<template>
  <div class="product-filter box1">
    <div action="#" class="form mb-0">
      <h4>Filter By</h4>
      <div class="filter-options price-filter">
        <h6>Price</h6>
        <input
          type="range"
          class="form-control-range mb-3"
          v-model="price"
          :min="minPrice"
          :max="maxPrice"
          @change="priceChanged"
          data-toggle="tooltip"
          data-placement="top"
          :title="price"
        />
        <ul class="list">
          <li>
            <input
              type="text"
              class="form-control"
              v-model="minPrice"
              readonly
            />
          </li>
          <li><span>to</span></li>
          <li>
            <input type="text" class="form-control" v-model="price" readonly />
          </li>
        </ul>
      </div>

      <div class="filter-options dropdown">
        <h6
          class="heading"
          data-toggle="collapse"
          data-target="#feature-filter"
          role="button"
          aria-expanded="true"
          aria-controls="feature-filter"
        >
          Key Features
        </h6>
        <div class="wrapper collapse show" id="feature-filter">
          <ul class="list">
            <li>
              <div class="checkbox">
                <input
                  type="checkbox"
                  v-model="oneTouch"
                  id="feature-0"
                  @change="oneTouchChanged"
                />
                <label for="feature-0">OneTouch DoubleCup</label>
              </div>
            </li>
            <li>
              <div class="checkbox">
                <input
                  type="checkbox"
                  v-model="hotWaterFunction"
                  id="feature-1"
                  @change="hotWaterFunctionChanged"
                />
                <label for="feature-1">Hot water function</label>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6
          class="heading"
          data-toggle="collapse"
          data-target="#appliance-filter"
          role="button"
          aria-expanded="true"
          aria-controls="appliance-filter"
        >
          Smart Appliance
        </h6>
        <div class="wrapper collapse show" id="appliance-filter">
          <ul class="list">
            <li>
              <div class="checkbox">
                <input
                  type="checkbox"
                  v-model="remoteDiagnostics"
                  id="appliance-1"
                  @change="remoteDiagnosticsChanged"
                />
                <label for="appliance-1">Remote Diagnostics</label>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6
          class="heading"
          data-toggle="collapse"
          data-target="#height-filter"
          role="button"
          aria-expanded="true"
          aria-controls="height-filter"
        >
          Height
        </h6>
        <div class="wrapper collapse show" id="height-filter">
           <VueMultiselect
            v-model="height"
            :options="heights"
            :multiple="true"
            :close-on-select="true"
            open-direction="bottom"
            placeholder="Select heights"
            :searchable="false"
            selectLabel=""
            deselectLabel=""
            @input="heightChanged"
          />

        </div>
      </div>
      <div class="filter-options dropdown">
        <h6
          class="heading"
          data-toggle="collapse"
          data-target="#width-filter"
          role="button"
          aria-expanded="true"
          aria-controls="width-filter"
        >
          Width
        </h6>
        <div class="wrapper collapse show" id="width-filter">
           <VueMultiselect
            v-model="width"
            :options="widths"
            :multiple="true"
            :close-on-select="true"
            open-direction="bottom"
            placeholder="Select widths"
            :searchable="false"
            selectLabel=""
            deselectLabel=""
            @input="widthChanged"
          />

        </div>
      </div>
      <div class="filter-options dropdown">
        <h6
          class="heading"
          data-toggle="collapse"
          data-target="#depth-filter"
          role="button"
          aria-expanded="true"
          aria-controls="depth-filter"
        >
          Depth
        </h6>
        <div class="wrapper collapse show" id="depth-filter">
           <VueMultiselect
            v-model="depth"
            :options="depths"
            :multiple="true"
            :close-on-select="true"
            open-direction="bottom"
            placeholder="Select depths"
            :searchable="false"
            selectLabel=""
            deselectLabel=""
            @input="depthChanged"
          />

        </div>
      </div>
      <div class="filter-options dropdown">
        <h6
          class="heading"
          data-toggle="collapse"
          data-target="#colour-filter"
          role="button"
          aria-expanded="true"
          aria-controls="colour-filter"
        >
          Colour
        </h6>
        <div class="wrapper collapse show" id="colour-filter">
           <VueMultiselect
            v-model="color"
            :options="colors"
            :multiple="true"
            :close-on-select="true"
            open-direction="bottom"
            placeholder="Select colours"
            :searchable="false"
            selectLabel=""
            deselectLabel=""
            @input="colorChanged"
          />

        </div>
      </div>
      <div class="filter-options dropdown">
        <h6
          class="heading"
          data-toggle="collapse"
          data-target="#efficiency-filter"
          role="button"
          aria-expanded="true"
          aria-controls="efficiency-filter"
        >
          Energy Efficiency
        </h6>
        <div class="wrapper collapse show" id="efficiency-filter">
           <VueMultiselect
            v-model="energyEfficiency"
            :options="energyEfficiencies"
            :multiple="true"
            :close-on-select="true"
            open-direction="bottom"
            placeholder="Select energy efficiencies"
            :searchable="false"
            selectLabel=""
            deselectLabel=""
            @input="energyEfficiencyChanged"
          />

        </div>
      </div>
      <div class="filter-options dropdown">
        <h6
          class="heading"
          data-toggle="collapse"
          data-target="#installation-filter"
          role="button"
          aria-expanded="true"
          aria-controls="installation-filter"
        >
          Installation Type
        </h6>
        <div class="wrapper collapse show" id="installation-filter">
          <ul class="list">
            <li v-for="(item, index) in installations" v-bind:key="item">
              <div class="checkbox">
                <input
                  type="checkbox"
                  :value="item"
                  v-model="installation"
                  :id="'installation-' + index"
                  @change="installationChanged"
                />
                <label :for="'installation-' + index">{{ item }}</label>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6
          class="heading"
          data-toggle="collapse"
          data-target="#typology-filter"
          role="button"
          aria-expanded="true"
          aria-controls="type-filter"
        >
          Typology
        </h6>
        <div class="wrapper collapse show" id="typology-filter">
          <ul class="list">
            <li v-for="(item, index) in typologies" v-bind:key="item">
              <div class="checkbox">
                <input
                  type="checkbox"
                  :value="item"
                  v-model="typology"
                  :id="'typology-' + index"
                  @change="typologyChanged"
                />
                <label :for="'typology-' + index">{{ item }}</label>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6
          class="heading"
          data-toggle="collapse"
          data-target="#control-filter"
          role="button"
          aria-expanded="true"
          aria-controls="type-filter"
        >
          Type of control
        </h6>
        <div class="wrapper collapse show" id="control-filter">
          <ul class="list">
            <li v-for="(item, index) in controlTypes" v-bind:key="item">
              <div class="checkbox">
                <input
                  type="checkbox"
                  :value="item"
                  v-model="controlType"
                  :id="'control-' + index"
                  @change="controlTypeChanged"
                />
                <label :for="'control-' + index">{{ item }}</label>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6
          class="heading"
          data-toggle="collapse"
          data-target="#system-filter"
          role="button"
          aria-expanded="true"
          aria-controls="type-filter"
        >
          Coffee System
        </h6>
        <div class="wrapper collapse show" id="system-filter">
          <ul class="list">
            <li v-for="(item, index) in coffeeSystems" v-bind:key="item">
              <div class="checkbox">
                <input
                  type="checkbox"
                  :value="item"
                  v-model="coffeeSystem"
                  :id="'system-' + index"
                  @change="coffeeSystemChanged"
                />
                <label :for="'system-' + index">{{ item }}</label>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6
          class="heading"
          data-toggle="collapse"
          data-target="#foaming-filter"
          role="button"
          aria-expanded="true"
          aria-controls="type-filter"
        >
          Foaming aid
        </h6>
        <div class="wrapper collapse show" id="foaming-filter">
          <ul class="list">
            <li v-for="(item, index) in foamingAids" v-bind:key="item">
              <div class="checkbox">
                <input
                  type="checkbox"
                  :value="item"
                  v-model="foamingAid"
                  :id="'foaming-' + index"
                  @change="foamingAidChanged"
                />
                <label :for="'foaming-' + index">{{ item }}</label>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6
          class="heading"
          data-toggle="collapse"
          data-target="#accessory-filter"
          role="button"
          aria-expanded="true"
          aria-controls="type-filter"
        >
          Included accessories
        </h6>
        <div class="wrapper collapse show" id="accessory-filter">
          <VueMultiselect
            v-model="accessory"
            :options="accessories"
            :multiple="true"
            :close-on-select="true"
            open-direction="bottom"
            placeholder="Select accessories"
            :searchable="false"
            selectLabel=""
            deselectLabel=""
            @input="accessoryChanged"
          />

        </div>
      </div>
      <div class="filter-options dropdown">
        <h6
          class="heading"
          data-toggle="collapse"
          data-target="#intake-filter"
          role="button"
          aria-expanded="true"
          aria-controls="type-filter"
        >
          Water intake
        </h6>
        <div class="wrapper collapse show" id="intake-filter">
          <ul class="list">
            <li v-for="(item, index) in waterIntakes" v-bind:key="item">
              <div class="checkbox">
                <input
                  type="checkbox"
                  :value="item"
                  v-model="waterIntake"
                  :id="'intake-' + index"
                  @change="waterIntakeChanged"
                />
                <label :for="'intake-' + index">{{ item }}</label>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6
          class="heading"
          data-toggle="collapse"
          data-target="#connectivity-filter"
          role="button"
          aria-expanded="true"
          aria-controls="type-filter"
        >
          Connectivity type
        </h6>
        <div class="wrapper collapse show" id="connectivity-filter">
          <ul class="list">
            <li v-for="(item, index) in connectivities" v-bind:key="item">
              <div class="checkbox">
                <input
                  type="checkbox"
                  :value="item"
                  v-model="connectivity"
                  :id="'connectivity-' + index"
                  @change="connectivityChanged"
                />
                <label :for="'connectivity-' + index">{{ item }}</label>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import ProductService from "@/services/product.service";
import VueMultiselect from "vue-multiselect";

export default {
  name: "CoffeeMachineFilters",
  data: function() {
    return {
      minPrice: 0,
      maxPrice: 0,
      heights: [],
      widths: [],
      depths: [],
      colors: [],
      installations: [],
      energyEfficiencies: [],
      connectivities: [],
      foamingAids: [],
      waterIntakes: [],
      coffeeSystems: [],
      accessories: [],
      controlTypes: [],
      typologies: [],

      price: 0,
      height: [],
      width: [],
      depth: [],
      color: [],
      installation: [],
      energyEfficiency: [],
      oneTouch: false,
      remoteDiagnostics: false,
      hotWaterFunction: false,
      typology: [],
      controlType: [],
      accessory: [],
      coffeeSystem: [],
      waterIntake: [],
      foamingAid: [],
      connectivity: [],
    };
  },
  components: {
    VueMultiselect,
  },
  watch: {
    // call again the method if the route changes
    selectedBrands: function() {
      this.bindValues();
    },
  },
  mounted() {
    this.bindValues();
    if (this.selectedFilters.category == this.selectedSubCategory.id) {
      this.existingFilters();
    }
  },
  computed: {
    selectedSubCategory() {
      return this.$store.state.subCategory.selectedSubCategory;
    },
    selectedBrands() {
      return this.$store.state.brand.selectedBrands;
    },
    selectedFilters() {
      return this.$store.state.filters.selectedFilters;
    },
  },
  methods: {
    bindValues() {
      ProductService.minMaxPriceFor(
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.minPrice = response.price__min;
        this.maxPrice = response.price__max;
        this.price = response.price__max;
        if (this.selectedFilters.price__lte) {
          this.price = Number(this.selectedFilters.price__lte);
        }
      });

      ProductService.valuesFor(
        "attr_height",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.heights = response.filter((item) => item !== null);
      });
      ProductService.valuesFor(
        "attr_width",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.widths = response.filter((item) => item !== null);
      });
      ProductService.valuesFor(
        "attr_depth",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.depths = response.filter((item) => item !== null);
      });
      ProductService.valuesFor(
        "attr_inst_type",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.installations = response.filter((item) => item !== null);
      });
      ProductService.valuesFor(
        "attr_col_main",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.colors = response.filter((item) => item !== null);
      });
      ProductService.valuesFor(
        "attr_energy_class_2010",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.energyEfficiencies = response.filter((item) => item !== null);
      });
      ProductService.valuesFor(
        "attr_homeconnect_connectivity",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.connectivities = response.filter((item) => item !== null);
      });
      ProductService.valuesFor(
        "attr_foaming",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.foamingAids = response.filter((item) => item !== null);
      });
      ProductService.valuesFor(
        "attr_water_intake",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.waterIntakes = response.filter((item) => item !== null);
      });
      ProductService.valuesFor(
        "attr_coffee_sys",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.coffeeSystems = response.filter((item) => item !== null);
      });
      ProductService.valuesFor(
        "attr_accessories_incl",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.accessories = response.filter((item) => item !== null);
      });
      ProductService.valuesFor(
        "attr_control_setting",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.controlTypes = response.filter((item) => item !== null);
      });
      ProductService.valuesFor(
        "attr_typology",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.typologies = response.filter((item) => item !== null);
      });
    },

    priceChanged() {
      let filters = { price__lte: this.price };
      this.$emit("changeFilters", filters);
    },

    oneTouchChanged() {
      let filters = {
        attr_onetouchdoublecup: this.oneTouch ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },

    hotWaterFunctionChanged() {
      let filters = {
        attr_hot_water: this.hotWaterFunction ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },

    heightChanged() {
      let filters = {
        attr_height__in: _.map(this.height, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    widthChanged() {
      let filters = {
        attr_width__in: _.map(this.width, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    depthChanged() {
      let filters = {
        attr_depth__in: _.map(this.depth, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    installationChanged() {
      let filters = {
        attr_inst_type__in: _.map(this.installation, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    colorChanged() {
      let filters = {
        attr_col_main__in: _.map(this.color, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    energyEfficiencyChanged() {
      let filters = {
        attr_energy_class_2010__in: _.map(this.energyEfficiency, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    foamingAidChanged() {
      let filters = {
        attr_foaming__in: _.map(this.foamingAid, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    typologyChanged() {
      let filters = {
        attr_typology__in: _.map(this.typology, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    controlTypeChanged() {
      let filters = {
        attr_control_setting__in: _.map(this.controlType, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    coffeeSystemChanged() {
      let filters = {
        attr_coffee_sys__in: _.map(this.coffeeSystem, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    accessoryChanged() {
      let filters = {
        attr_accessories_incl__in: _.map(this.accessory, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    waterIntakeChanged() {
      let filters = {
        attr_water_intake__in: _.map(this.waterIntake, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    connectivityChanged() {
      let filters = {
        attr_homeconnect_connectivity__in: _.map(this.connectivity, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    remoteDiagnosticsChanged() {
      let filters = {
        attr_remote_diagnostics: this.remoteDiagnostics ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },

    existingFilters() {
      this.remoteDiagnostics = this.selectedFilters.attr_remote_diagnostics;
      this.oneTouch = this.selectedFilters.attr_onetouchdoublecup;
      this.hotWaterFunction = this.selectedFilters.attr_hot_water;

      if (this.selectedFilters.attr_foaming__in) {
        let sortedAids = [];
        let unsortedAids = this.selectedFilters.attr_foaming__in.split('","');
        unsortedAids.map((aid) => {
          aid = aid.replaceAll('"', "");
          sortedAids.push(aid);
        });
        this.foamingAid = sortedAids;
      }

      if (this.selectedFilters.attr_height__in) {
        let sortedHeights = [];
        let unsortedHeights = this.selectedFilters.attr_height__in.split('","');
        unsortedHeights.map((height) => {
          height = height.replaceAll('"', "");
          sortedHeights.push(height);
        });
        this.height = sortedHeights;
      }

      if (this.selectedFilters.attr_width__in) {
        let sortedWidths = [];
        let unsortedWidths = this.selectedFilters.attr_width__in.split('","');
        unsortedWidths.map((width) => {
          width = width.replaceAll('"', "");
          sortedWidths.push(width);
        });
        this.width = sortedWidths;
      }

      if (this.selectedFilters.attr_depth__in) {
        let sortedDepths = [];
        let unsortedDepths = this.selectedFilters.attr_depth__in.split('","');
        unsortedDepths.map((depth) => {
          depth = depth.replaceAll('"', "");
          sortedDepths.push(depth);
        });
        this.depth = sortedDepths;
      }

      if (this.selectedFilters.attr_inst_type__in) {
        let sortedInstallations = [];
        let unsortedInstallations = this.selectedFilters.attr_inst_type__in.split(
          '","'
        );
        unsortedInstallations.map((installation) => {
          installation = installation.replaceAll('"', "");
          sortedInstallations.push(installation);
        });
        this.installation = sortedInstallations;
      }

      if (this.selectedFilters.attr_col_main__in) {
        let sortedColors = [];
        let unsortedColors = this.selectedFilters.attr_col_main__in.split(
          '","'
        );
        unsortedColors.map((color) => {
          color = color.replaceAll('"', "");
          sortedColors.push(color);
        });
        this.color = sortedColors;
      }

      if (this.selectedFilters.attr_energy_class_2010__in) {
        let sortedEnergies = [];
        let unsortedEnergies = this.selectedFilters.attr_energy_class_2010__in.split(
          '","'
        );
        unsortedEnergies.map((energy) => {
          energy = energy.replaceAll('"', "");
          sortedEnergies.push(energy);
        });
        this.energyEfficiency = sortedEnergies;
      }

      if (this.selectedFilters.attr_control_setting__in) {
        let sortedControls = [];
        let unsortedControls = this.selectedFilters.attr_control_setting__in.split(
          '","'
        );
        unsortedControls.map((control) => {
          control = control.replaceAll('"', "");
          sortedControls.push(control);
        });
        this.controlType = sortedControls;
      }
      if (this.selectedFilters.attr_coffee_sys__in) {
        let sortedSystems = [];
        let unsortedSystems = this.selectedFilters.attr_coffee_sys__in.split(
          '","'
        );
        unsortedSystems.map((system) => {
          system = system.replaceAll('"', "");
          sortedSystems.push(system);
        });
        this.coffeeSystem = sortedSystems;
      }

      if (this.selectedFilters.attr_accessories_incl__in) {
        let sortedAccessories = [];
        let unsortedAccessories = this.selectedFilters.attr_accessories_incl__in.split(
          '","'
        );
        unsortedAccessories.map((accessory) => {
          accessory = accessory.replaceAll('"', "");
          sortedAccessories.push(accessory);
        });
        this.accessory = sortedAccessories;
      }

      if (this.selectedFilters.attr_water_intake__in) {
        let sortedIntakes = [];
        let unsortedIntakes = this.selectedFilters.attr_water_intake__in.split(
          '","'
        );
        unsortedIntakes.map((intake) => {
          intake = intake.replaceAll('"', "");
          sortedIntakes.push(intake);
        });
        this.waterIntake = sortedIntakes;
      }

      if (this.selectedFilters.attr_homeconnect_connectivity__in) {
        let sortedConnectivities = [];
        let unsortedConnectivities = this.selectedFilters.attr_homeconnect_connectivity__in.split(
          '","'
        );
        unsortedConnectivities.map((connectivity) => {
          connectivity = connectivity.replaceAll('"', "");
          sortedConnectivities.push(connectivity);
        });
        this.connectivity = sortedConnectivities;
      }

      if (this.selectedFilters.attr_typology__in) {
        let sortedTypologies = [];
        let unsortedTypologies = this.selectedFilters.attr_typology__in.split(
          '","'
        );
        unsortedTypologies.map((typology) => {
          typology = typology.replaceAll('"', "");
          sortedTypologies.push(typology);
        });
        this.typology = sortedTypologies;
      }
    },
  },
};
</script>

<style scoped></style>
