var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-filter box1" }, [
    _c("div", { staticClass: "form mb-0", attrs: { action: "#" } }, [
      _c("h4", [_vm._v("Filter By")]),
      _c("div", { staticClass: "filter-options price-filter" }, [
        _c("h6", [_vm._v("Price")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.price,
              expression: "price"
            }
          ],
          staticClass: "form-control-range mb-3",
          attrs: {
            type: "range",
            min: _vm.minPrice,
            max: _vm.maxPrice,
            "data-toggle": "tooltip",
            "data-placement": "top",
            title: _vm.price
          },
          domProps: { value: _vm.price },
          on: {
            change: _vm.priceChanged,
            __r: function($event) {
              _vm.price = $event.target.value
            }
          }
        }),
        _c("ul", { staticClass: "list" }, [
          _c("li", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.minPrice,
                  expression: "minPrice"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", readonly: "" },
              domProps: { value: _vm.minPrice },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.minPrice = $event.target.value
                }
              }
            })
          ]),
          _vm._m(0),
          _c("li", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.price,
                  expression: "price"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", readonly: "" },
              domProps: { value: _vm.price },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.price = $event.target.value
                }
              }
            })
          ])
        ])
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#feature-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "feature-filter"
            }
          },
          [_vm._v(" Key Features ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "feature-filter" }
          },
          [
            _c("ul", { staticClass: "list" }, [
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.oneTouch,
                        expression: "oneTouch"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-0" },
                    domProps: {
                      checked: Array.isArray(_vm.oneTouch)
                        ? _vm._i(_vm.oneTouch, null) > -1
                        : _vm.oneTouch
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.oneTouch,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.oneTouch = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.oneTouch = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.oneTouch = $$c
                          }
                        },
                        _vm.oneTouchChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-0" } }, [
                    _vm._v("OneTouch DoubleCup")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.hotWaterFunction,
                        expression: "hotWaterFunction"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-1" },
                    domProps: {
                      checked: Array.isArray(_vm.hotWaterFunction)
                        ? _vm._i(_vm.hotWaterFunction, null) > -1
                        : _vm.hotWaterFunction
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.hotWaterFunction,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.hotWaterFunction = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.hotWaterFunction = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.hotWaterFunction = $$c
                          }
                        },
                        _vm.hotWaterFunctionChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-1" } }, [
                    _vm._v("Hot water function")
                  ])
                ])
              ])
            ])
          ]
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#appliance-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "appliance-filter"
            }
          },
          [_vm._v(" Smart Appliance ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "appliance-filter" }
          },
          [
            _c("ul", { staticClass: "list" }, [
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.remoteDiagnostics,
                        expression: "remoteDiagnostics"
                      }
                    ],
                    attrs: { type: "checkbox", id: "appliance-1" },
                    domProps: {
                      checked: Array.isArray(_vm.remoteDiagnostics)
                        ? _vm._i(_vm.remoteDiagnostics, null) > -1
                        : _vm.remoteDiagnostics
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.remoteDiagnostics,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.remoteDiagnostics = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.remoteDiagnostics = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.remoteDiagnostics = $$c
                          }
                        },
                        _vm.remoteDiagnosticsChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "appliance-1" } }, [
                    _vm._v("Remote Diagnostics")
                  ])
                ])
              ])
            ])
          ]
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#height-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "height-filter"
            }
          },
          [_vm._v(" Height ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "height-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.heights,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select heights",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.heightChanged },
              model: {
                value: _vm.height,
                callback: function($$v) {
                  _vm.height = $$v
                },
                expression: "height"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#width-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "width-filter"
            }
          },
          [_vm._v(" Width ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "width-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.widths,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select widths",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.widthChanged },
              model: {
                value: _vm.width,
                callback: function($$v) {
                  _vm.width = $$v
                },
                expression: "width"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#depth-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "depth-filter"
            }
          },
          [_vm._v(" Depth ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "depth-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.depths,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select depths",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.depthChanged },
              model: {
                value: _vm.depth,
                callback: function($$v) {
                  _vm.depth = $$v
                },
                expression: "depth"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#colour-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "colour-filter"
            }
          },
          [_vm._v(" Colour ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "colour-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.colors,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select colours",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.colorChanged },
              model: {
                value: _vm.color,
                callback: function($$v) {
                  _vm.color = $$v
                },
                expression: "color"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#efficiency-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "efficiency-filter"
            }
          },
          [_vm._v(" Energy Efficiency ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "efficiency-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.energyEfficiencies,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select energy efficiencies",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.energyEfficiencyChanged },
              model: {
                value: _vm.energyEfficiency,
                callback: function($$v) {
                  _vm.energyEfficiency = $$v
                },
                expression: "energyEfficiency"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#installation-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "installation-filter"
            }
          },
          [_vm._v(" Installation Type ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "installation-filter" }
          },
          [
            _c(
              "ul",
              { staticClass: "list" },
              _vm._l(_vm.installations, function(item, index) {
                return _c("li", { key: item }, [
                  _c("div", { staticClass: "checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.installation,
                          expression: "installation"
                        }
                      ],
                      attrs: { type: "checkbox", id: "installation-" + index },
                      domProps: {
                        value: item,
                        checked: Array.isArray(_vm.installation)
                          ? _vm._i(_vm.installation, item) > -1
                          : _vm.installation
                      },
                      on: {
                        change: [
                          function($event) {
                            var $$a = _vm.installation,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = item,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.installation = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.installation = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.installation = $$c
                            }
                          },
                          _vm.installationChanged
                        ]
                      }
                    }),
                    _c("label", { attrs: { for: "installation-" + index } }, [
                      _vm._v(_vm._s(item))
                    ])
                  ])
                ])
              }),
              0
            )
          ]
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#typology-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "type-filter"
            }
          },
          [_vm._v(" Typology ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "typology-filter" }
          },
          [
            _c(
              "ul",
              { staticClass: "list" },
              _vm._l(_vm.typologies, function(item, index) {
                return _c("li", { key: item }, [
                  _c("div", { staticClass: "checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.typology,
                          expression: "typology"
                        }
                      ],
                      attrs: { type: "checkbox", id: "typology-" + index },
                      domProps: {
                        value: item,
                        checked: Array.isArray(_vm.typology)
                          ? _vm._i(_vm.typology, item) > -1
                          : _vm.typology
                      },
                      on: {
                        change: [
                          function($event) {
                            var $$a = _vm.typology,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = item,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.typology = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.typology = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.typology = $$c
                            }
                          },
                          _vm.typologyChanged
                        ]
                      }
                    }),
                    _c("label", { attrs: { for: "typology-" + index } }, [
                      _vm._v(_vm._s(item))
                    ])
                  ])
                ])
              }),
              0
            )
          ]
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#control-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "type-filter"
            }
          },
          [_vm._v(" Type of control ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "control-filter" }
          },
          [
            _c(
              "ul",
              { staticClass: "list" },
              _vm._l(_vm.controlTypes, function(item, index) {
                return _c("li", { key: item }, [
                  _c("div", { staticClass: "checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.controlType,
                          expression: "controlType"
                        }
                      ],
                      attrs: { type: "checkbox", id: "control-" + index },
                      domProps: {
                        value: item,
                        checked: Array.isArray(_vm.controlType)
                          ? _vm._i(_vm.controlType, item) > -1
                          : _vm.controlType
                      },
                      on: {
                        change: [
                          function($event) {
                            var $$a = _vm.controlType,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = item,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.controlType = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.controlType = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.controlType = $$c
                            }
                          },
                          _vm.controlTypeChanged
                        ]
                      }
                    }),
                    _c("label", { attrs: { for: "control-" + index } }, [
                      _vm._v(_vm._s(item))
                    ])
                  ])
                ])
              }),
              0
            )
          ]
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#system-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "type-filter"
            }
          },
          [_vm._v(" Coffee System ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "system-filter" }
          },
          [
            _c(
              "ul",
              { staticClass: "list" },
              _vm._l(_vm.coffeeSystems, function(item, index) {
                return _c("li", { key: item }, [
                  _c("div", { staticClass: "checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.coffeeSystem,
                          expression: "coffeeSystem"
                        }
                      ],
                      attrs: { type: "checkbox", id: "system-" + index },
                      domProps: {
                        value: item,
                        checked: Array.isArray(_vm.coffeeSystem)
                          ? _vm._i(_vm.coffeeSystem, item) > -1
                          : _vm.coffeeSystem
                      },
                      on: {
                        change: [
                          function($event) {
                            var $$a = _vm.coffeeSystem,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = item,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.coffeeSystem = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.coffeeSystem = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.coffeeSystem = $$c
                            }
                          },
                          _vm.coffeeSystemChanged
                        ]
                      }
                    }),
                    _c("label", { attrs: { for: "system-" + index } }, [
                      _vm._v(_vm._s(item))
                    ])
                  ])
                ])
              }),
              0
            )
          ]
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#foaming-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "type-filter"
            }
          },
          [_vm._v(" Foaming aid ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "foaming-filter" }
          },
          [
            _c(
              "ul",
              { staticClass: "list" },
              _vm._l(_vm.foamingAids, function(item, index) {
                return _c("li", { key: item }, [
                  _c("div", { staticClass: "checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.foamingAid,
                          expression: "foamingAid"
                        }
                      ],
                      attrs: { type: "checkbox", id: "foaming-" + index },
                      domProps: {
                        value: item,
                        checked: Array.isArray(_vm.foamingAid)
                          ? _vm._i(_vm.foamingAid, item) > -1
                          : _vm.foamingAid
                      },
                      on: {
                        change: [
                          function($event) {
                            var $$a = _vm.foamingAid,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = item,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.foamingAid = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.foamingAid = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.foamingAid = $$c
                            }
                          },
                          _vm.foamingAidChanged
                        ]
                      }
                    }),
                    _c("label", { attrs: { for: "foaming-" + index } }, [
                      _vm._v(_vm._s(item))
                    ])
                  ])
                ])
              }),
              0
            )
          ]
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#accessory-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "type-filter"
            }
          },
          [_vm._v(" Included accessories ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "accessory-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.accessories,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select accessories",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.accessoryChanged },
              model: {
                value: _vm.accessory,
                callback: function($$v) {
                  _vm.accessory = $$v
                },
                expression: "accessory"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#intake-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "type-filter"
            }
          },
          [_vm._v(" Water intake ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "intake-filter" }
          },
          [
            _c(
              "ul",
              { staticClass: "list" },
              _vm._l(_vm.waterIntakes, function(item, index) {
                return _c("li", { key: item }, [
                  _c("div", { staticClass: "checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.waterIntake,
                          expression: "waterIntake"
                        }
                      ],
                      attrs: { type: "checkbox", id: "intake-" + index },
                      domProps: {
                        value: item,
                        checked: Array.isArray(_vm.waterIntake)
                          ? _vm._i(_vm.waterIntake, item) > -1
                          : _vm.waterIntake
                      },
                      on: {
                        change: [
                          function($event) {
                            var $$a = _vm.waterIntake,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = item,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.waterIntake = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.waterIntake = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.waterIntake = $$c
                            }
                          },
                          _vm.waterIntakeChanged
                        ]
                      }
                    }),
                    _c("label", { attrs: { for: "intake-" + index } }, [
                      _vm._v(_vm._s(item))
                    ])
                  ])
                ])
              }),
              0
            )
          ]
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#connectivity-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "type-filter"
            }
          },
          [_vm._v(" Connectivity type ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "connectivity-filter" }
          },
          [
            _c(
              "ul",
              { staticClass: "list" },
              _vm._l(_vm.connectivities, function(item, index) {
                return _c("li", { key: item }, [
                  _c("div", { staticClass: "checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.connectivity,
                          expression: "connectivity"
                        }
                      ],
                      attrs: { type: "checkbox", id: "connectivity-" + index },
                      domProps: {
                        value: item,
                        checked: Array.isArray(_vm.connectivity)
                          ? _vm._i(_vm.connectivity, item) > -1
                          : _vm.connectivity
                      },
                      on: {
                        change: [
                          function($event) {
                            var $$a = _vm.connectivity,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = item,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.connectivity = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.connectivity = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.connectivity = $$c
                            }
                          },
                          _vm.connectivityChanged
                        ]
                      }
                    }),
                    _c("label", { attrs: { for: "connectivity-" + index } }, [
                      _vm._v(_vm._s(item))
                    ])
                  ])
                ])
              }),
              0
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("span", [_vm._v("to")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }